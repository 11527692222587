import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import classes from "../Assets/Styles/TestingAudio.module.css";
import { useNavigate } from "react-router-dom";

function DeleteConfirmationModal({
  projectName,
  onClose,
  projectId,
  handleDeleteProjectAPI,
}) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(`.${classes["popup-inner"]}`)) {
        onClose();
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    setInterval(() => {
      setIsOpen(true);
    }, 100);
  }, []);

  return (
    <Grid item container className={classes["popup"]}>
      <Grid className={classes["popup-inner"]}>
        <Grid item container justifyContent="space-between">
          <Grid item fontSize="15px">
            Do you want to delete {projectName} Project?
          </Grid>
          {/* <Grid item xs={1} sx={{ cursor: "pointer", color: "#5570DC" }}>
            <CancelIcon onClick={onClose} fontSize="medium" />
          </Grid> */}
        </Grid>
        <Grid item container justifyContent="space-evenly" marginTop={1}>
          <Grid item>
            <Button
              color="warning"
              onClick={async () => {
                await handleDeleteProjectAPI(projectId);
                navigate(`/projectlist`);
                onClose();
              }}
            >
              yes
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={onClose}>
              no
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DeleteConfirmationModal;
