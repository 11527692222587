import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserById } from "../MProject-services.proxy";
import { UPDATE_USERS } from "../MProjectConst";

const UserEditScreen = () => {
  const params = useParams();
  const { id: userId } = params;
  const navigate = useNavigate();
  // const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserById(userId);
        // setUser(response.data);
        setEmail(response.data.email);
        setName(response.data.name);
        setAdmin(response.data.isAdmin);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [userId]);

  const updatedUser = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(UPDATE_USERS, {
        id: userId,
        name: name,
        isAdmin: admin,
      });
      console.log(response.data.msg);
      navigate("/admin/users");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid item container justifyContent="center">
      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Edit User
        </Grid>
        <form onSubmit={updatedUser}>
          <Grid item container direction="column">
            <Grid item color="black">
              <h1>ID: {userId}</h1>
            </Grid>

            <Grid item fontSize={23} marginBottom={1.5} marginTop={1.5}>
              <div style={{ color: "black" }}>Name</div>
            </Grid>
            <TextField
              type="text"
              placeholder="Enter your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Grid item fontSize={23} marginBottom={1.5}>
              <div style={{ color: "black" }}>Email</div>
            </Grid>
            <TextField
              type="text"
              placeholder="Enter your email"
              value={email}
              disabled
              onChange={(e) => setEmail(e.target.value)}
            />

            <Grid item fontSize={23} marginBottom={1.5}>
              <div style={{ color: "black" }}>Admin</div>
            </Grid>

            <Select
              value={admin}
              multiline
              onChange={(event) => setAdmin(event.target.value)}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            marginTop={3.5}
            marginBottom={4}
          >
            <Button variant="contained" type="submit">
              Updated
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default UserEditScreen;
