export const tasksCode = [
  { name: "Transcriber", code: "transcriber" },
  { name: "Transcriber", code: "transcribe" },

  { name: "Translator", code: "translate" },
  { name: "Translator", code: "translator" },

  { name: "Performance Direction", code: "performancedirection" },
  { name: "Face ID Editor", code: "speakerfaceidentification" },
  { name: "Lip QA Reviewer", code: "lipqualityassurance" },
  { name: "Final QA Reviewer", code: "qualityassurance" },
];

export const tasksCodeFormal = [
  { name: "Transcriber", code: "transcriber" },
  { name: "Transcribe", code: "transcribe" },

  { name: "Translate", code: "translate" },
  { name: "Translator", code: "translator" },

  { name: "Performance Direction", code: "performancedirection" },
  { name: "Face ID Editing", code: "speakerfaceidentification" },
  { name: "Lip QA Review", code: "lipqualityassurance" },
  { name: "Final QA Review", code: "qualityassurance" },
];

export const workTypes = [
  "transcribe",
  "translate",
  "performancedirection",
  "speakerfaceidentification",
  "lipqualityassurance",
  "qualityassurance",
];
