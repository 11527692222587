import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_USERS } from "../MProjectConst";

const UserListScreen = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_USERS);
        setUsers(response.data.usersArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  return (
    <Grid item container justifyContent="center">
      {/* {console.log(users)} */}
      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Users
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>EMAIL</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow
                  key={user.id}
                  //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{index}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.isAdmin ? <>True</> : <>False</>}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      onClick={() => navigate(`/admin/user/${user.id}`)}
                    >
                      Edit
                    </Button>
                    &nbsp;
                    <Button
                      color="error"
                      // onClick={() => deleteHandler(user)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserListScreen;
