import "react-video-seek-slider/styles.css";
import { VideoSeekSlider } from "react-video-seek-slider";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import classes from "../../Assets/Styles/Transcriber.module.css";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import AudioSlider from "./AudioSlider";
import { primaryColor, secondaryColor } from "../../utils/constants";
import TaskButton from "../Buttons/TaskButton";

const VideoSlider = memo((props) => {
  const player = useRef(null);
  const animationFrameRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [audioSpeed, setAudioSpeed] = useState(1);
  const [toggleOnOff, setToggleOnOff] = useState(true);
  const [playTogether, setPlayTogether] = useState(0);
  const [pauseTime, setPauseTime] = useState(0);

  function formatTime(seconds, decimalPlaces = 2) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(decimalPlaces);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(3 + decimalPlaces, "0")}`;
  }

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (player.current) {
      player.current.currentTime = time / 1000;
      setCurrentTime(time);
    }
  }, []);

  const updateCurrentTime = useCallback(() => {
    if (player.current) {
      setCurrentTime(player.current.currentTime * 1000);
      animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
    }
  }, []);

  const handlePlay = useCallback(() => {
    animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
  }, [updateCurrentTime]);

  const handlePause = () => {
    cancelAnimationFrame(animationFrameRef.current);
  };

  const handleDataLoaded = () => {
    if (player.current) {
      setMaxTime(player.current.duration * 1000);
    }
  };

  const handleProgress = () => {
    if (player.current) {
      const buffer = player.current.buffered;
      if (buffer.length > 0) {
        let currentBuffer = 0;
        const inSeconds = player.current.currentTime;
        for (let i = 0; i < buffer.length; i++) {
          if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
            currentBuffer = i;
            break;
          }
        }
        setProgress(buffer.end(currentBuffer) * 1000 || 0);
      }
    }
  };

  const togglePlayPause = () => {
    const video = player.current;
    if (video) {
      if (isPlaying) {
        video.pause();
        props.setPausePlay(false);
      } else {
        video.play();
        props.setPausePlay(true);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    if (player.current) {
      player.current.volume = newVolume;
      setVolume(newVolume);
    }
  };

  const handleSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    if (player.current) {
      player.current.playbackRate = newSpeed;
      setAudioSpeed(newSpeed);
    }
  };

  useEffect(() => {
    if (player.current) {
      player.current.addEventListener("play", handlePlay);
      player.current.addEventListener("pause", handlePause);
      player.current.addEventListener("loadeddata", handleDataLoaded);
      player.current.addEventListener("progress", handleProgress);
      return () => {
        if (player.current) {
          player.current.removeEventListener("play", handlePlay);
          player.current.removeEventListener("pause", handlePause);
          player.current.removeEventListener("loadeddata", handleDataLoaded);
          player.current.removeEventListener("progress", handleProgress);
        }
        cancelAnimationFrame(animationFrameRef.current);
      };
    }
  }, [handlePlay]);

  useEffect(() => {
    props.displayAudioTime((currentTime / 1000).toFixed(3));
  }, [currentTime]);

  useEffect(() => {
    if (props.seekTimeIndex === 1 && toggleOnOff && player.current) {
      player.current.play();
      setIsPlaying(true);
      player.current.currentTime = props.seekTime;
      setCurrentTime(props.seekTime * 1000);
    }
    if (toggleOnOff && player.current) {
      player.current.currentTime = props.seekTime;
      setCurrentTime(props.seekTime * 1000);
    }
  }, [props.seekTimeIndex]);

  useEffect(() => {
    if (!toggleOnOff && player.current && isPlaying) {
      player.current.pause();
      setIsPlaying(false);
    }
  }, [toggleOnOff]);

  const handleTwoPlaysTogether = () => {
    togglePlayPause();
    setPlayTogether(playTogether + 1);
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={5.85}
      xl={5.85}
      container
      direction="row"
      height="715px"
    >
      <Grid
        item
        container
        className={classes["AudioOutPut"]}
        direction="column"
      >
        <Grid item container className={classes["AudioOutPutVideo"]}>
          <video
            // controls={true}
            // autoPlay={true}
            // className="video-preview"
            // ref={toggleOnOff ? player : null}
            ref={player}
            width="100%"
            height="100%"
            src={props.source}
            muted={!toggleOnOff}
          >
            <source type="video/mp4" />
          </video>
        </Grid>
        <Grid
          item
          container
          className={classes["AudioOutPutController"]}
          bgcolor={toggleOnOff ? "" : "#2c2c2d"}
        >
          <Grid
            paddingLeft={1}
            paddingRight={1}
            item
            sx={{
              width: 3000,
              height: 20,
            }}
            bgcolor="#EBEBEB"
          >
            <VideoSeekSlider
              max={maxTime}
              currentTime={currentTime}
              bufferTime={progress}
              onChange={handleTimeChange}
              limitTimeTooltipBySides={true}
              secondsPrefix="00:"
              minutesPrefix="0:"
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            gap={1}
            marginLeft={1}
            marginBottom={1}
          >
            <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
              <FastRewindIcon
                fontSize="large"
                onClick={() => handleTimeChange(currentTime - 100)}
              />
            </Grid>
            <Grid
              item
              sx={{ color: `${primaryColor}`, cursor: "pointer" }}
              // height="100%"
            >
              {isPlaying ? (
                <PauseCircleIcon
                  fontSize="large"
                  onClick={toggleOnOff ? togglePlayPause : null}
                />
              ) : (
                <PlayCircleIcon
                  fontSize="large"
                  onClick={toggleOnOff ? togglePlayPause : null}
                />
              )}
            </Grid>
            <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
              <FastForwardIcon
                fontSize="large"
                onClick={() => handleTimeChange(currentTime + 100)}
              />
            </Grid>
            <Grid
              item
              container
              className={classes["AudioOutPutShownTime"]}
              justifyContent="center"
              alignItems="center"
              marginRight={2}
              xs={1.5}
            >
              {formatTime(currentTime / 1000)}
            </Grid>
            <Grid>Speed:</Grid>
            <Grid
              item
              container
              // className={classes["AudioOutPutShownTime"]}
              justifyContent="center"
              alignItems="center"
              marginRight={1}
              xs={1}
            >
              <select
                value={audioSpeed}
                onChange={(e) => handleSpeedChange(e)}
                className={classes["AudioOutPutShowTimeOptionBox"]}
              >
                <option value="0.07">0.07x</option>
                <option value="0.1">0.1x</option>
                <option value="0.15">0.15x</option>
                <option value="0.25">0.25x</option>
                <option value="0.5">0.5x</option>
                <option value="1">1.0x</option>
                <option value="1.5">1.5x</option>
                <option value="2">2.0x</option>
                <option value="2.5">2.5x</option>
                <option value="3">3.0x</option>
              </select>
            </Grid>

            <Grid>Pause:</Grid>
            <Grid
              item
              container
              className={classes["AudioOutPutShownTime"]}
              justifyContent="center"
              alignItems="center"
              marginRight={1}
              xs={1}
            >
              <select
                value={pauseTime}
                onChange={(event) => setPauseTime(event.target.value)}
                className={classes["AudioOutPutShowTimeOptionBox"]}
              >
                <option value="0">0.0s</option>
                <option value="1">1.0s</option>
                <option value="2">2.0s</option>
                <option value="3">3.0s</option>
                <option value="4">4.0s</option>
                <option value="5">5.0s</option>
                <option value="6">6.0s</option>
                <option value="7">7.0s</option>
                <option value="8">8.0s</option>
              </select>
            </Grid>
            <Grid item sx={{ width: 200 }}>
              <Stack spacing={2} direction="row" alignItems="center">
                <VolumeDown />
                <Slider
                  value={volume}
                  onChange={(e) => handleVolumeChange(e)}
                  min={0}
                  max={1}
                  step={0.1}
                  aria-label="Volume"
                  sx={{ color: `${primaryColor}` }}
                />
                <VolumeUp />
              </Stack>
            </Grid>
            <Grid item xs={0.5}>
              <input
                type="checkbox"
                checked={toggleOnOff}
                onChange={(e) => setToggleOnOff(e.target.checked)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container marginTop={1}>
        <AudioSlider
          source={props.audioSource}
          seekTime={props.seekTime}
          seekTimeIndex={props.seekTimeIndex}
          displayAudioTime={props.displayAudioTime}
          playTogether={playTogether}
          toggleOnOff={toggleOnOff}
          setToggleOnOff={setToggleOnOff}
          setPausePlay={props.setPausePlay}
        />
      </Grid>
      <Grid item container alignItems="center" marginTop={1}>
        <Grid item>
          <TaskButton onClick={() => handleTwoPlaysTogether()}>
            Play together {" " + isPlaying ? "OFF" : "ON"}
          </TaskButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default VideoSlider;
