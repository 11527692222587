import React, { useState } from "react";

function SRTSubtitleParser({ setSrtData }) {
  const [parsedSubtitles, setParsedSubtitles] = useState(null);

  // Function to handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const srtContent = e.target.result;
      const parsedSubtitles = parseSRTContent(srtContent);
      setParsedSubtitles(parsedSubtitles);
      setSrtData(parsedSubtitles);
    };

    reader.readAsText(file);
  };

  // Function to parse SRT content
  const parseSRTContent = (srtContent) => {
    // Normalize newlines and split into segments
    const segments = srtContent
      .replace(/\r\n|\r/g, "\n")
      .trim()
      .split("\n\n");
    const parsedSubtitles = [];

    segments.forEach((segment) => {
      const lines = segment.split("\n");
      const subtitle_id = parseInt(lines[0]);
      const timecode = lines[1];
      const [start_time, end_time] = timecode.split(" --> ");
      const start_seconds = srtTimeToSeconds(start_time);
      const end_seconds = srtTimeToSeconds(end_time);
      const text = lines.slice(2).join(" ");

      parsedSubtitles.push({
        id: subtitle_id,
        start: start_seconds,
        end: end_seconds,
        text: text.trim(),
      });
    });

    return parsedSubtitles;
  };

  // Function to convert SRT time to seconds
  const srtTimeToSeconds = (time_str) => {
    const [hours, minutes, seconds_milliseconds] = time_str.split(":");
    const [seconds, milliseconds] = seconds_milliseconds.split(",");
    return (
      parseInt(hours) * 3600 +
      parseInt(minutes) * 60 +
      parseInt(seconds) +
      parseInt(milliseconds) / 1000
    );
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {/* {parsedSubtitles && (
        <div>
          <h2>Parsed Subtitles</h2>
          <ul>
            {parsedSubtitles.map((subtitle) => (
              <li key={subtitle.id}>
                <div>Subtitle ID: {subtitle.id}</div>
                <div>Start Time: {subtitle.start} ms</div>
                <div>End Time: {subtitle.end} ms</div>
                <div>Text: {subtitle.text}</div>
              </li>
            ))}
          </ul>
        </div>
      )} */}
      {/* {console.log(parsedSubtitles)} */}
    </div>
  );
}

export default SRTSubtitleParser;
