import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Button = styled.button`
  padding: 10px 20px;
  border-color: transparent;
  background-color: var(--global-second-color);
  border: 1px solid var(--global-color);
  border-radius: 5px;
  color: #f5f5f5;
  font-family: var(--global-text-font);
  font-size: 15px;
  cursor: pointer;

  &:hover {
    border-color: transparent;
    background-color: var(--global-color);
    border: 1px solid var(--global-second-color);
    color: var(--global-second-color);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  /* Add dynamic width and height */
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
`;

const GlobalButton = ({
  onClick,
  children,
  className,
  width,
  height,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      className={className}
      width={width}
      height={height}
      {...props}
    >
      {children}
    </Button>
  );
};

GlobalButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

// GlobalButton.defaultProps = {
//   onClick: () => {},
//   className: "",
//   width: "auto",
//   height: "auto",
// };

export default GlobalButton;
