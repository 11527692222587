import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCYIcsF07RhoTzcrhFnkfgUGA6NVJ2Jcr0",
  authDomain: "video-dub-e0a3e.firebaseapp.com",
  projectId: "video-dub-e0a3e",
  storageBucket: "video-dub-e0a3e.appspot.com",
  messagingSenderId: "1063779538546",
  appId: "1:1063779538546:web:5c325f4d08936ec43094eb",
  measurementId: "G-4Y1Q92091S",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);

export const auth = getAuth(app);

export async function getDownloadUrlForFile(filename) {
  try {
    const storageRef = ref(storage, filename);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl;
  } catch (error) {
    console.error("Error getting download URL:", error);
    throw error;
  }
}

export async function getDownloadUrlForFileWithoutHandler(filename) {
  const storageRef = ref(storage, filename);
  const downloadUrl = await getDownloadURL(storageRef);
  return downloadUrl;
}
