import "react-video-seek-slider/styles.css";
import { VideoSeekSlider } from "react-video-seek-slider";
import { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import classes from "../../Assets/Styles/SFIScreen.module.css";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { ToastContainer, toast } from "react-toastify";
import { primaryColor } from "../../utils/constants";
// import { frameBoxs } from "../../Data/FrameBoxs";
import TaskButton from "../Buttons/TaskButton";

const displayWidth = 1500;
const displayHeight = 400;

const VideoSliderLips = (props) => {
  const frameBoxs = props.selectedCharacterBBox;
  const originalWidth = props.originalWidth; // yahyas width
  const originalHeight = props.originalHeight; // yahyas height
  const [videoFPS, setVideoFPS] = useState(props.videoFPS);

  const player = useRef(null);
  const interval = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentFPS, setCurrentFPS] = useState(0);

  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [audioSpeed, setAudioSpeed] = useState(1);

  const canvasRef = useRef(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [videoWidth, setVideoWidth] = useState(displayWidth);
  const [videoHeight, setVideoHeight] = useState(displayHeight);

  const [pauseTime, setPauseTime] = useState(0);
  const [drawingSquareD, setDrawingSquareD] = useState({
    left: "",
    top: "",
    width: "",
    height: "",
  });
  // const [boxDimensions, setBoxDimensions] = useState();

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (!player.current?.currentTime) {
      return;
    }

    player.current.currentTime = time / 1000;
    setCurrentTime(time);

    // console.log({ time, offsetTime });
  }, []);

  const handlePlay = () => {
    interval.current = setInterval(() => {
      setCurrentTime((player.current?.currentTime || 0) * 1000);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(interval.current);
  };

  const handleDataLoaded = () => {
    setMaxTime((player.current?.duration || 0) * 1000);
  };

  const handleProgress = () => {
    const buffer = player?.current?.buffered;

    if (((buffer?.length > 0 && player.current?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = player.current?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          currentBuffer = i;
          break;
        }
      }

      setProgress(buffer.end(currentBuffer) * 1000 || 0);
    }
  };

  // const handlePlayPause = () => {
  //   setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  // };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    player.current.volume = newVolume;
    setVolume(newVolume);
  };

  const togglePlayPause = () => {
    const video = player.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (!player) {
      return;
    }

    player.current?.addEventListener("play", handlePlay);
    player.current?.addEventListener("pause", handlePause);
    player.current?.addEventListener("loadeddata", handleDataLoaded);
    player.current?.addEventListener("progress", handleProgress);
  }, [player]);

  useEffect(() => {
    props.displayAudioTime(currentTime / 1000);
    setCurrentFPS(Math.floor((currentTime / 1000) * videoFPS));
  }, [currentTime]);

  useEffect(() => {
    if (!player.current?.currentTime) {
      return;
    }

    player.current.currentTime = props.seekTime;
    setCurrentTime(props.seekTime * 1000);
  }, [props.seekTimeIndex, props.seekTime]);

  function formatTime(seconds, decimalPlaces = 2) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Calculate the fraction of seconds with the specified decimal places
    const fractionSeconds = remainingSeconds.toFixed(decimalPlaces);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = fractionSeconds.padStart(3 + decimalPlaces, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const handleSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    player.current.playbackRate = newSpeed;
    setAudioSpeed(newSpeed);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(player?.current?.currentTime * 1000);
    }, 1);

    return () => clearInterval(interval);
  }, []);

  // -------------------- Video lipper functions

  const handleMouseDown = (e) => {
    setIsDrawing(true);
    setStartPos({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    const width = e.nativeEvent.offsetX - startPos.x;
    const height = e.nativeEvent.offsetY - startPos.y;

    ctx.strokeStyle = "red";
    ctx.lineWidth = 2;

    // props.SquareDimensions({
    //   top: startPos.x,
    //   left: startPos.y,
    //   width: width,
    //   height: height,
    // });

    if (width < 0) {
      setDrawingSquareD({
        left: startPos.x - Math.abs(width),
        top: startPos.y,
        width: Math.abs(width),
        height: Math.abs(height),
      });
    } else {
      setDrawingSquareD({
        left: startPos.x,
        top: startPos.y,
        width: Math.abs(width),
        height: Math.abs(height),
      });
    }

    ctx.strokeRect(startPos.x, startPos.y, width, height);
  };

  const handleClear = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    setDrawingSquareD({
      left: "",
      top: "",
      width: "",
      height: "",
    });
  };

  //------------------- lipsing functions

  const drawBBSquares = (x, y, width, height, color) => {
    const ctx = canvasRef.current.getContext("2d");

    const WholeRectangleWidth = videoWidth;
    const WholeRectangleHeight = videoHeight;

    const ScaleFactor = WholeRectangleHeight / originalHeight;
    const newVideoWidth = ScaleFactor * originalWidth;
    const leftGrayPart = (WholeRectangleWidth - newVideoWidth) / 2;

    const xmin = x;
    const ymin = y;
    const xmax = width;
    const ymax = height;

    const Left = xmin * ScaleFactor + leftGrayPart;
    const Top = ymin * ScaleFactor;
    const Width = (xmax - xmin) * ScaleFactor;
    const Height = (ymax - ymin) * ScaleFactor;

    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    ctx.strokeRect(Left, Top, Width, Height);
  };

  function expandFaceBox(
    facebox,
    img_width,
    img_height,
    expansion_factor = 1.3
  ) {
    if (facebox) {
      const center_x = (facebox[0] + facebox[2]) / 2;
      const center_y = (facebox[1] + facebox[3]) / 2;
      const half_width = center_x - facebox[0];
      const half_height = center_y - facebox[1];
      const new_half_width = expansion_factor * half_width;
      const new_half_height = expansion_factor * half_height;

      const min_x = Math.max(center_x - new_half_width, 0);
      const min_y = Math.max(center_y - new_half_height, 0);
      const max_x = Math.min(center_x + new_half_width, img_width);
      const max_y = Math.min(center_y + new_half_height, img_height);

      return [min_x, min_y, max_x, max_y];
    }
  }

  function findFaceBoxInRegion(region, frameFaceboxList) {
    let faceBox = [];
    const imgHeight = originalHeight;
    const imgWidth = originalWidth;
    const buffer = 2;

    if (
      Array.isArray(frameFaceboxList) &&
      Array.isArray(region) &&
      region.length === 4
    ) {
      for (let item of frameFaceboxList) {
        let itemFb = item["bbox"];
        if (itemFb?.length === 0) {
          continue;
        }
        let xmin = Math.max(itemFb[0], buffer);
        let ymin = Math.max(itemFb[1], buffer);
        let xmax = Math.min(itemFb[2], imgWidth - buffer);
        let ymax = Math.min(itemFb[3], imgHeight - buffer);

        if (
          xmin >= region[0] &&
          ymin >= region[1] &&
          xmax <= region[2] &&
          ymax <= region[3]
        ) {
          return itemFb;
        }
      }
    }
    return faceBox;
  }

  // function trackFaceAlongDialogueOld(
  //   initialFacebox,
  //   dialogueStartFrame,
  //   dialogueEndFrame,
  //   initialFrame,
  //   faceDetectionList,
  //   imgWidth,
  //   imgHeight,
  //   expansionFactor = 1.3
  // ) {
  //   const forwardFaceboxList = [];
  //   let facebox = initialFacebox?.slice();
  //   let currentFrame = initialFrame;

  //   while (currentFrame < dialogueEndFrame && facebox?.length !== 0) {
  //     const region = expandFaceBox(
  //       facebox,
  //       imgWidth,
  //       imgHeight,
  //       expansionFactor
  //     );
  //     facebox = findFaceBoxInRegion(
  //       region,
  //       faceDetectionList[currentFrame + 1]
  //     );
  //     currentFrame++;
  //     if (facebox?.length !== 0) {
  //       forwardFaceboxList.push(facebox);
  //     }
  //   }

  //   const backwardsFaceboxList = [];
  //   facebox = initialFacebox?.slice();
  //   currentFrame = initialFrame;

  //   while (currentFrame > dialogueStartFrame && facebox?.length !== 0) {
  //     const region = expandFaceBox(
  //       facebox,
  //       imgWidth,
  //       imgHeight,
  //       expansionFactor
  //     );
  //     facebox = findFaceBoxInRegion(
  //       region,
  //       faceDetectionList[currentFrame - 1]
  //     );
  //     currentFrame--;
  //     if (facebox?.length !== 0) {
  //       backwardsFaceboxList.push(facebox);
  //     }
  //   }

  //   const firstFaceFrame = currentFrame + 1;
  //   backwardsFaceboxList.reverse();

  //   return {
  //     faceBoxes: [
  //       ...backwardsFaceboxList,
  //       [...initialFacebox],
  //       ...forwardFaceboxList,
  //     ],
  //     firstFaceFrame: firstFaceFrame,
  //   };
  // }

  function trackFaceAlongDialogueExtended(
    initialFacebox,
    dialogueStartFrame,
    dialogueEndFrame,
    initialFrame,
    faceDetectionList,
    imgWidth,
    imgHeight,
    expansionFactor = 1.3,
    extendBeyondDialogue = true
  ) {
    const forwardFaceboxList = [];
    let facebox = initialFacebox?.slice();
    let currentFrame = initialFrame;

    // Forward tracking
    while (
      (extendBeyondDialogue || currentFrame < dialogueEndFrame) &&
      facebox?.length !== 0
    ) {
      const region = expandFaceBox(
        facebox,
        imgWidth,
        imgHeight,
        expansionFactor
      );
      facebox = findFaceBoxInRegion(
        region,
        faceDetectionList[currentFrame + 1]
      );
      currentFrame++;
      if (facebox?.length !== 0) {
        forwardFaceboxList.push(facebox.slice());
      }
    }

    const backwardsFaceboxList = [];
    facebox = initialFacebox?.slice();
    currentFrame = initialFrame;

    // Backward tracking
    while (
      (extendBeyondDialogue || currentFrame > dialogueStartFrame) &&
      facebox?.length !== 0
    ) {
      const region = expandFaceBox(
        facebox,
        imgWidth,
        imgHeight,
        expansionFactor
      );
      facebox = findFaceBoxInRegion(
        region,
        faceDetectionList[currentFrame - 1]
      );
      currentFrame--;
      if (facebox?.length !== 0) {
        backwardsFaceboxList.push(facebox.slice());
      }
    }

    const firstFaceFrame = currentFrame + 1;
    backwardsFaceboxList.reverse();

    return {
      faceBoxes: [
        ...backwardsFaceboxList,
        initialFacebox,
        ...forwardFaceboxList,
      ],
      firstFaceFrame: firstFaceFrame,
    };
  }

  const calculateBoundingBox = (Left, Top, Width, Height) => {
    const WholeRectangleWidth = videoWidth;
    const WholeRectangleHeight = videoHeight;

    const ScaleFactor = WholeRectangleHeight / originalHeight;
    const newVideoWidth = ScaleFactor * originalWidth;
    const leftGrayPart = (WholeRectangleWidth - newVideoWidth) / 2;

    const xmin = (Left - leftGrayPart) / ScaleFactor;
    const ymin = Top / ScaleFactor;
    const xmax = xmin + Width / ScaleFactor;
    const ymax = ymin + Height / ScaleFactor;

    return { xmin, ymin, xmax, ymax };
  };

  function extractBBoxes(fbdata) {
    let fbdata2 = [];
    let start_frame = fbdata[0]["frame_index"];
    for (let i = 0; i < start_frame; i++) {
      fbdata2.push([{ bbox: [] }]);
    }
    let last_index = start_frame - 1;
    for (let item of fbdata) {
      if (item["frame_index"] > last_index + 1) {
        for (let i = last_index; i < item["frame_index"]; i++) {
          fbdata2.push([{ bbox: [] }]);
        }
      }
      let bboxes = item["bounding_boxes"];
      let bboxes2 = [];
      for (let bbox of bboxes) {
        bboxes2.push({
          bbox: [bbox["xmin"], bbox["ymin"], bbox["xmax"], bbox["ymax"]],
        });
      }
      fbdata2.push(bboxes2?.slice());
      last_index = item["frame_index"];
    }
    return fbdata2;
  }

  function faceTrackAlignment() {
    if (drawingSquareD.left === "") {
      toast.error("Draw the square on the speaker first!!");

      return;
    }

    const left = drawingSquareD.left;
    const top = drawingSquareD.top;
    const width = drawingSquareD.width;
    const height = drawingSquareD.height;
    const boundingBox = calculateBoundingBox(left, top, width, height);

    const drawedRegionBox = [
      boundingBox.xmin,
      boundingBox.ymin,
      boundingBox.xmax,
      boundingBox.ymax,
    ];

    const dialogueEndFrame = frameBoxs[frameBoxs.length - 1].frame_index;
    // const dialogueStartFrame = 0 //old
    const dialogueStartFrame = frameBoxs[0].frame_index;
    // const initialFrame = Number(Math.floor(props.frameTime * videoFPS));
    const initialFrame = currentFPS;
    // const faceDetectionList = extractBBoxes(frameBoxs); //expandedbeyoudDialogues off
    const faceDetectionList = extractBBoxes(props.rawBoundingBoxes); //expandedbeyoudDialogues on

    const initialFaceBox = findFaceBoxInRegion(
      drawedRegionBox,
      faceDetectionList[initialFrame]
    );

    if (initialFaceBox?.length <= 0) {
      toast.error("No blue box in this dimensions");
      return;
    }

    const imgWidth = originalWidth;
    const imgHeight = originalHeight;
    const expansionFactor = 1.3;

    const results = trackFaceAlongDialogueExtended(
      initialFaceBox,
      dialogueStartFrame,
      dialogueEndFrame,
      initialFrame,
      faceDetectionList,
      imgWidth,
      imgHeight,
      expansionFactor
    );

    const faceDetectionListCreatedObjects = createFaceDetectionObjects(
      results.firstFaceFrame,
      results.faceBoxes
    );

    let updatedBoxDimensions = [];

    if (props.boxDimensionsResults) {
      updatedBoxDimensions = mergeBboxsWithValidation(
        props.boxDimensionsResults,
        faceDetectionListCreatedObjects
      );
    } else {
      updatedBoxDimensions = [...faceDetectionListCreatedObjects];
    }

    // console.log(
    //   // initialFaceBox,
    //   dialogueStartFrame,
    //   dialogueEndFrame
    //   // initialFrame,
    //   // faceDetectionList,
    //   // imgWidth,
    //   // imgHeight,
    //   // expansionFactor
    // );

    // console.log(updatedBoxDimensions);

    props.setBoxDimensionsResults(updatedBoxDimensions);

    toast.success(
      "A new set of squares per frames has been added to the faces"
    );

    setDrawingSquareD({
      left: "",
      top: "",
      width: "",
      height: "",
    });
  }

  function createFaceDetectionObjects(firstFaceFrame, faceDetectionList) {
    const faceObjects = [];
    // console.log(faceDetectionList);

    for (let i = 0; i < faceDetectionList?.length; i++) {
      const frameNumber = firstFaceFrame + i;
      const bbox = faceDetectionList[i];
      faceObjects.push({ frame: frameNumber, bbox });
    }

    return faceObjects;
  }

  function mergeBboxsWithValidation(array1, array2) {
    const mergedArray = array1.map((obj1) => {
      const obj2 = array2.find((obj) => obj.frame === obj1.frame);
      return obj2 ? { ...obj1, ...obj2 } : obj1;
    });

    array2.forEach((obj2) => {
      if (!mergedArray.some((obj) => obj.frame === obj2.frame)) {
        mergedArray.push(obj2);
      }
    });

    mergedArray.sort((a, b) => a.frame - b.frame);

    return mergedArray;
  }

  //to know the video fps number
  useEffect(() => {
    setVideoFPS(props.videoFPS);
  }, [props.videoFPS]);

  useEffect(() => {
    const handleResize = () => {
      // Update canvas dimensions based on the container size
      const containerWidth = canvasRef.current.parentNode.offsetWidth;
      const containerHeight = canvasRef.current.parentNode.offsetHeight;

      setVideoWidth(containerWidth - 3);
      setVideoHeight(containerHeight - 3);
    };

    // Set initial canvas dimensions
    handleResize();

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.frameTimeSwitch) {
      handleClear();
      // const frameTime = Math.floor(props.frameTime * videoFPS);
      const frameTime = currentFPS;

      const boxOfFrames = frameBoxs;
      const color = "blue";
      const frame = boxOfFrames?.find((box) => box.frame_index === frameTime);

      if (frame && frame.bounding_boxes.length > 0) {
        frame.bounding_boxes.forEach((box) => {
          drawBBSquares(box.xmin, box.ymin, box.xmax, box.ymax, color);
        });
      }
    }
  }, [currentFPS]);

  useEffect(() => {
    if (props.frameTimeResultsSwitch) {
      if (
        props.boxDimensionsResults?.length > 0 ||
        props.selectedDialoguePanel.characterFrameBoxes?.length > 0
      ) {
        handleClear();
        // const frameTime = Math.floor(props.frameTimeResults * videoFPS);
        const frameTime = currentFPS;

        const boxOfFrames = props.boxDimensionsResults;

        const color = "yellow";

        const frame = boxOfFrames?.find(
          (box) => Number(box.frame) === Number(frameTime)
        );
        // console.log(frame);
        if (frame) {
          drawBBSquares(
            frame.bbox[0],
            frame.bbox[1],
            frame.bbox[2],
            frame.bbox[3],
            color
          );
        }
      }
    }
  }, [currentFPS]);

  useEffect(() => {
    if (props.deleteResultFrame || props.deleteResultFrame === 0) {
      handleClear();
      // const deletedFrame = Math.floor(props.deleteResultFrame * videoFPS);
      const deletedFrame = currentFPS;

      let updatedBoxDimensions = [...props.boxDimensionsResults];

      updatedBoxDimensions = updatedBoxDimensions.filter(
        (box) => Number(box.frame) !== Number(deletedFrame)
      );
      props.setBoxDimensionsResults(updatedBoxDimensions);
    }
  }, [props.deleteResultFrame]);

  useEffect(() => {
    if (props.selectCharacterFace !== 0) {
      faceTrackAlignment();
    }
  }, [props.selectCharacterFace]);

  // useEffect(() => {
  //   if (props.rawBoundingBoxes) {
  //     handleClear();

  //     const frameTime = Math.floor((currentTime / 1000) * videoFPS);
  //     console.log("current time: " + currentTime / 1000);
  //     console.log("frame time: " + frameTime);

  //     const boxOfFrames = props.rawBoundingBoxes;
  //     const color = "green";

  //     const frame = boxOfFrames.find((box) => box.frame_index === frameTime);

  //     if (frame && frame.bounding_boxes.length > 0) {
  //       console.log("bbox frame information: " + frame.frame_index);
  //       frame.bounding_boxes.forEach((box) => {
  //         drawBBSquares(box.xmin, box.ymin, box.xmax, box.ymax, color);
  //       });
  //     }
  //   }
  // }, [currentTime]);

  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      direction="column"
    >
      {/* {console.log(frameBoxs)} */}
      {/* {console.log(props.rawBoundingBoxes)} */}
      {/* {console.log(videoFPS)} */}

      <ToastContainer position="top-center" theme="dark" />
      <Grid item container className={classes["AudioOutPutVideo"]}>
        <video
          ref={player}
          width={videoWidth}
          height={videoHeight}
          style={{
            position: "absolute",
          }}
        >
          <source src={props.source} type="video/mp4" />
        </video>
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          width={videoWidth}
          height={videoHeight}
          style={{
            // border: "2px solid black",
            position: "absolute",
            top: 124,
            left: "6.25%",
          }}
        />
      </Grid>
      <Grid item container className={classes["AudioOutPutController"]}>
        <Grid
          paddingLeft={1}
          paddingRight={1}
          item
          sx={{
            width: 3000,
            height: 20,
          }}
          bgcolor="#EBEBEB"
        >
          <VideoSeekSlider
            style={{ color: "yellow" }}
            max={maxTime}
            currentTime={currentTime}
            bufferTime={progress}
            onChange={handleTimeChange}
            limitTimeTooltipBySides={true}
            secondsPrefix="00:"
            minutesPrefix="0:"
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          gap={1}
          marginLeft={1}
          marginBottom={0.5}
        >
          <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
            <FastRewindIcon
              fontSize="large"
              onClick={() => handleTimeChange(currentTime - 200)}
            />
          </Grid>
          <Grid
            item
            sx={{ color: `${primaryColor}`, cursor: "pointer" }}
            // height="100%"
          >
            {isPlaying ? (
              <PauseCircleIcon fontSize="large" onClick={togglePlayPause} />
            ) : (
              <PlayCircleIcon fontSize="large" onClick={togglePlayPause} />
            )}
          </Grid>
          <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
            <FastForwardIcon
              fontSize="large"
              onClick={() => handleTimeChange(currentTime + 200)}
            />
          </Grid>
          <Grid
            item
            container
            className={classes["AudioOutPutShownTime"]}
            justifyContent="center"
            alignItems="center"
            marginRight={2}
            xs={1.5}
          >
            {formatTime(currentTime / 1000)}
          </Grid>
          <Grid>Speed:</Grid>
          <Grid
            item
            container
            // className={classes["AudioOutPutShownTime"]}
            justifyContent="center"
            alignItems="center"
            marginRight={1}
            xs={1}
          >
            <select
              value={audioSpeed}
              onChange={(e) => handleSpeedChange(e)}
              className={classes["AudioOutPutShowTimeOptionBox"]}
            >
              <option value="0.1">0.1x</option>
              <option value="0.15">0.15x</option>
              <option value="0.25">0.25x</option>
              <option value="0.5">0.5x</option>
              <option value="1">1.0x</option>
              <option value="1.5">1.5x</option>
              <option value="2">2.0x</option>
              <option value="2.5">2.5x</option>
              <option value="3">3.0x</option>
            </select>
          </Grid>
          <Grid>Pause:</Grid>
          <Grid
            item
            container
            className={classes["AudioOutPutShownTime"]}
            justifyContent="center"
            alignItems="center"
            marginRight={1}
            xs={1}
          >
            <select
              value={pauseTime}
              onChange={(event) => setPauseTime(event.target.value)}
              className={classes["AudioOutPutShowTimeOptionBox"]}
            >
              <option value="0">0.0s</option>
              <option value="1">1.0s</option>
              <option value="2">2.0s</option>
              <option value="3">3.0s</option>
              <option value="4">4.0s</option>
              <option value="5">5.0s</option>
              <option value="6">6.0s</option>
              <option value="7">7.0s</option>
              <option value="8">8.0s</option>
            </select>
          </Grid>
          <Grid item sx={{ width: 200 }}>
            <Stack spacing={2} direction="row" alignItems="center">
              <VolumeDown />
              <Slider
                value={volume}
                onChange={(e) => handleVolumeChange(e)}
                min={0}
                max={1}
                step={0.1}
                aria-label="Volume"
                sx={{ color: `${primaryColor}` }}
              />
              <VolumeUp />
            </Stack>
          </Grid>
          <Grid item marginLeft={2}>
            <TaskButton onClick={handleClear}>Clear Square</TaskButton>
          </Grid>
          {/* <Grid item marginLeft={2}>
            <button
              onClick={faceTrackAlignment}
              className={classes["AudioWorkPlaceButton"]}
            >
              Show Square
            </button>
          </Grid> */}

          {/* <Grid item marginLeft={5}>
            {"Left: " +
              drawingSquareD.top +
              " | Top: " +
              drawingSquareD.left +
              " | Width: " +
              drawingSquareD.width +
              " | Height: " +
              drawingSquareD.height}
          </Grid> */}

          <Grid item marginLeft={5}>
            {drawingSquareD.top
              ? `${drawingSquareD.left} | ${drawingSquareD.top} | ${drawingSquareD.width} | ${drawingSquareD.height}`
              : ""}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VideoSliderLips;
