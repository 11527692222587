import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../Assets/Styles/Header.module.css";
import { Button, Grid, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import logo from "../Assets/Images/logo.png";

import { Store } from "../Store";
import AdminRolesNavBar from "../Components/HeaderComponents/AdminRolesNavBar";

import { workTypes } from "../Data/tasksCode";
import {
  convertToDate,
  getLanguageName,
  getTaskNameFormal,
} from "../utils/generalFunctions";
import { primaryColor } from "../utils/constants";
import EmployeRolesNavBar from "../Components/HeaderComponents/EmployeRolesNavBar";

const labelColors = "var(--global-color)";

const Header = () => {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, projectFile, employeTasks } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("projectFile");
    window.location.href = "/";
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = React.useState(null);
  const openAdmin = Boolean(adminAnchorEl);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWork = (project, role, language) => {
    ctxDispatch({ type: "PROJECT_FILE", payload: project });
    localStorage.setItem("projectFile", JSON.stringify(project));

    if (role === "transcribe") navigate("/work/transcriber");
    else if (role === "translate") navigate(`/work/translator/${language}`);
    else if (role === "performancedirection")
      navigate(`/work/performancedirection/${language}`);
    else if (role === "lipqualityassurance")
      navigate(`/work/lipqualityassurance/${language}`);
    else if (role === "speakerfaceidentification")
      navigate("/work/speakerfaceidentification");
    else if (role === "qualityassurance")
      navigate(`/work/qualityassurance/${language}`);
  };

  const handleNavigate = () => {
    if (userInfo?.isAdmin) {
      ctxDispatch({ type: "PROJECT_FILE_CLEAR" });
      // localStorage.removeItem("projectFile");
      navigate("/adminlanding");
    } else {
      navigate("/landing");
    }
  };

  function transformRoles(roles) {
    return roles
      .map((role) => {
        switch (role) {
          case "transcriber":
            return "transcribe";
          case "translator":
            return "translate";
          case "performancedirection":
          case "lipqualityassurance":
          case "speakerfaceidentification":
          case "qualityassurance":
            return role;
          default:
            return null;
        }
      })
      .filter((role) => role !== null);
  }

  function getRolesByName(data, name) {
    // Check if data is an array
    if (!Array.isArray(data)) {
      console.error("Error: data is not an array");
      return [];
    }

    const project = data?.find((item) => item?.name === name);
    return project ? project?.roles : [];
  }

  return (
    <>
      {userInfo ? (
        <Grid
          item
          container
          className={classes["headerContainer"]}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            container
            alignContent="center"
            alignItems="center"
            xs={2}
            marginLeft={5}
            sx={{ cursor: "pointer" }}
            gap={1}
          >
            <Grid item onClick={() => handleNavigate()}>
              <img src={logo} alt="logo" className={classes["logo"]} />
            </Grid>
            <Grid
              item
              onClick={() => handleNavigate()}
              className={classes["logoName"]}
            >
              DubFrog
            </Grid>
          </Grid>
          {Object.keys(projectFile).length > 0 && (
            <>
              {userInfo.isAdmin ? (
                <Grid
                  container
                  item
                  xs={2}
                  direction="row"
                  gap={1}
                  alignItems="center"
                >
                  <Grid
                    item
                    sx={{
                      color: primaryColor,
                      fontFamily: "var(--global-text-font)",
                      fontSize: "19px",
                      fontWeight: "600",
                    }}
                  >
                    {projectFile.name}
                  </Grid>
                  <Grid item>
                    <AdminRolesNavBar
                      workTypes={workTypes}
                      projectFile={projectFile}
                      handleWork={handleWork}
                      getTaskNameFormal={getTaskNameFormal}
                      getLanguageName={getLanguageName}
                      convertToDate={convertToDate}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={2}
                  direction="row"
                  gap={1}
                  alignItems="center"
                >
                  <Grid
                    item
                    sx={{
                      color: primaryColor,
                      fontFamily: "var(--global-text-font)",
                      fontSize: "19px",
                      fontWeight: "600",
                    }}
                  >
                    {projectFile.name}
                  </Grid>
                  <Grid item>
                    <EmployeRolesNavBar
                      workTypes={transformRoles(
                        getRolesByName(employeTasks, projectFile.name)
                      )}
                      projectFile={projectFile}
                      handleWork={handleWork}
                      getTaskNameFormal={getTaskNameFormal}
                      getLanguageName={getLanguageName}
                      convertToDate={convertToDate}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={2}>
            <Grid item container alignItems="center" direction="row">
              <Grid item marginRight={0.3}>
                <PersonIcon className={classes["icons"]} fontSize="large" />
              </Grid>
              <Grid item className={classes["textFont"]}>
                <Button
                  onClick={handleClick}
                  style={{
                    color: `${labelColors}`,
                    fontFamily: "Livvic",
                    textTransform: "none",
                  }}
                >
                  {userInfo.name} <ArrowDropDownIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    style: {
                      width: 144,
                    },
                  }}
                >
                  <MenuItem onClick={handleClose} sx={{ fontFamily: "Livvic" }}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={signoutHandler}
                    sx={{ fontFamily: "Livvic" }}
                  >
                    Sign out
                  </MenuItem>
                </Menu>
              </Grid>

              {(() => {
                if (userInfo.isAdmin) {
                  return (
                    <Grid item marginRight={7}>
                      <Button
                        onClick={(event) => {
                          setAdminAnchorEl(event.currentTarget);
                        }}
                        style={{
                          color: `${labelColors}`,
                          fontFamily: "Livvic",
                          textTransform: "none",
                        }}
                      >
                        Admin <ArrowDropDownIcon />
                      </Button>
                      <Menu
                        anchorEl={adminAnchorEl}
                        open={openAdmin}
                        onClose={() => setAdminAnchorEl(null)}
                        MenuListProps={{
                          style: {
                            width: 100,
                          },
                        }}
                      >
                        <MenuItem onClick={() => setAdminAnchorEl(null)}>
                          <Link
                            style={{
                              textDecoration: "none",
                              color: `${labelColors}`,
                              fontFamily: "Livvic",
                            }}
                            to="/admin/users"
                          >
                            Users
                          </Link>
                        </MenuItem>
                      </Menu>
                    </Grid>
                  );
                } else {
                  return <></>;
                }
              })()}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          className={classes["headerContainer"]}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            item
            container
            alignContent="center"
            alignItems="center"
            xs={3}
            marginLeft={5}
            gap={1}
          >
            <Grid item>
              <img src={logo} alt="logo" className={classes["logo"]} />
            </Grid>
            <Grid item className={classes["logoName"]}>
              DubFrog
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid
              item
              container
              alignItems="center"
              direction="row"
              // justifyContent="space-between"
            >
              <Grid item marginRight={5}>
                <HelpIcon fontSize="large" className={classes["icons"]} />
              </Grid>
              <Grid item marginRight={5}>
                <CircleNotificationsIcon
                  fontSize="large"
                  className={classes["icons"]}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      )}
    </>
  );
};

export default Header;
