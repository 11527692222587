import "react-video-seek-slider/styles.css";
import { VideoSeekSlider } from "react-video-seek-slider";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import classes from "../../Assets/Styles/Transcriber.module.css";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { primaryColor } from "../../utils/constants";

const AudioSlider = memo((props) => {
  const player = useRef(null);
  const interval = useRef(null);
  const [currentTime, setCurrentTime] = useState(0.1);
  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [audioSpeed, setAudioSpeed] = useState(1);
  //   const [toggleOnOff, setToggleOnOff] = useState(false);

  //-----

  const [pauseTime, setPauseTime] = useState(0);

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (!player.current?.currentTime) {
      return;
    }

    player.current.currentTime = time / 1000;
    setCurrentTime(time);

    // console.log({ time, offsetTime });
  }, []);

  const handlePlay = useCallback(() => {
    interval.current = setInterval(() => {
      setCurrentTime((player.current?.currentTime || 0) * 1000);
    }, 1000);
  }, []);
  const handlePause = () => {
    clearInterval(interval.current);
  };

  const handleDataLoaded = () => {
    setMaxTime((player.current?.duration || 0) * 1000);
  };

  const handleProgress = () => {
    const buffer = player?.current?.buffered;

    if (((buffer?.length > 0 && player.current?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = player.current?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          currentBuffer = i;
          break;
        }
      }

      setProgress(buffer.end(currentBuffer) * 1000 || 0);
    }
  };

  const handlePlayPause = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    player.current.volume = newVolume;
    setVolume(newVolume);
  };

  const togglePlayPause = () => {
    const video = player.current;
    if (isPlaying) {
      props.setPausePlay(false);
      video.pause();
    } else {
      props.setPausePlay(true);
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (!player) {
      return;
    }

    player.current?.addEventListener("play", handlePlay);
    player.current?.addEventListener("pause", handlePause);
    player.current?.addEventListener("loadeddata", handleDataLoaded);
    player.current?.addEventListener("progress", handleProgress);
  }, [player]);

  useEffect(() => {
    props.displayAudioTime((currentTime / 1000).toFixed(2));
  }, [currentTime]);

  useEffect(() => {
    if (props.seekTimeIndex === 1 && !props.toggleOnOff) {
      player.current.play();
      setIsPlaying(true);
      player.current.currentTime = props.seekTime;
      setCurrentTime(props.seekTime * 1000);
    }
    if (!props.toggleOnOff) {
      if (!player.current?.currentTime) {
        return;
      }

      player.current.currentTime = props.seekTime;
      setCurrentTime(props.seekTime * 1000);
    }
  }, [props.seekTimeIndex]);

  function formatTime(seconds, decimalPlaces = 2) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Calculate the fraction of seconds with the specified decimal places
    const fractionSeconds = remainingSeconds.toFixed(decimalPlaces);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = fractionSeconds.padStart(3 + decimalPlaces, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const handleSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    player.current.playbackRate = newSpeed;
    setAudioSpeed(newSpeed);
  };
  // updating the current time in milliseconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(player.current.currentTime * 1000);
    }, 1);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (props.toggleOnOff) {
      const video = player.current;
      if (isPlaying) {
        video.pause();
      } else {
        return;
      }
      setIsPlaying(!isPlaying);
    }
  }, [props.toggleOnOff]);

  useEffect(() => {
    if (props.playTogether >= 1) {
      togglePlayPause();
    }
  }, [props.playTogether]);

  return (
    <>
      <Grid item container>
        <video ref={player} width="0%" height="0%" src={props.source}>
          <source type="video/mp4" />
        </video>
      </Grid>
      <Grid item>Dialogue Only</Grid>
      <Grid
        item
        container
        className={classes["AudioOutPutController"]}
        paddingTop={0}
        bgcolor={!props.toggleOnOff ? "" : "#2c2c2d"}
        // height="20px"
      >
        <Grid
          paddingLeft={1}
          paddingRight={1}
          item
          sx={{
            width: 3000,
            height: 20,
          }}
          bgcolor="#EBEBEB"
        >
          <VideoSeekSlider
            style={{ color: "yellow" }}
            max={maxTime}
            currentTime={currentTime}
            bufferTime={progress}
            onChange={handleTimeChange}
            limitTimeTooltipBySides={true}
            secondsPrefix="00:"
            minutesPrefix="0:"
          />
        </Grid>

        <Grid
          item
          container
          direction="row"
          alignItems="center"
          gap={1}
          marginLeft={1}
          marginBottom={1}
        >
          <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
            <FastRewindIcon
              fontSize="large"
              onClick={() => handleTimeChange(currentTime - 100)}
            />
          </Grid>
          <Grid
            item
            sx={{ color: `${primaryColor}`, cursor: "pointer" }}
            // height="100%"
          >
            {isPlaying ? (
              <PauseCircleIcon
                fontSize="large"
                onClick={!props.toggleOnOff ? togglePlayPause : null}
              />
            ) : (
              <PlayCircleIcon
                fontSize="large"
                onClick={!props.toggleOnOff ? togglePlayPause : null}
              />
            )}
          </Grid>
          <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
            <FastForwardIcon
              fontSize="large"
              onClick={() => handleTimeChange(currentTime + 100)}
            />
          </Grid>
          <Grid
            item
            container
            className={classes["AudioOutPutShownTime"]}
            justifyContent="center"
            alignItems="center"
            marginRight={2}
            xs={1.5}
          >
            {formatTime(currentTime / 1000)}
          </Grid>
          <Grid>Speed:</Grid>
          <Grid
            item
            container
            // className={classes["AudioOutPutShownTime"]}
            justifyContent="center"
            alignItems="center"
            marginRight={1}
            xs={1}
          >
            <select
              value={audioSpeed}
              onChange={(e) => handleSpeedChange(e)}
              className={classes["AudioOutPutShowTimeOptionBox"]}
            >
              <option value="0.07">0.07x</option>
              <option value="0.1">0.1x</option>
              <option value="0.15">0.15x</option>
              <option value="0.25">0.25x</option>
              <option value="0.5">0.5x</option>
              <option value="1">1.0x</option>
              <option value="1.5">1.5x</option>
              <option value="2">2.0x</option>
              <option value="2.5">2.5x</option>
              <option value="3">3.0x</option>
            </select>
          </Grid>

          <Grid>Pause:</Grid>
          <Grid
            item
            container
            className={classes["AudioOutPutShownTime"]}
            justifyContent="center"
            alignItems="center"
            marginRight={1}
            xs={1}
          >
            <select
              value={pauseTime}
              onChange={(event) => setPauseTime(event.target.value)}
              className={classes["AudioOutPutShowTimeOptionBox"]}
            >
              <option value="0">0.0s</option>
              <option value="1">1.0s</option>
              <option value="2">2.0s</option>
              <option value="3">3.0s</option>
              <option value="4">4.0s</option>
              <option value="5">5.0s</option>
              <option value="6">6.0s</option>
              <option value="7">7.0s</option>
              <option value="8">8.0s</option>
            </select>
          </Grid>
          <Grid item sx={{ width: 200 }}>
            <Stack spacing={2} direction="row" alignItems="center">
              <VolumeDown />
              <Slider
                value={volume}
                onChange={(e) => handleVolumeChange(e)}
                min={0}
                max={1}
                step={0.1}
                aria-label="Volume"
                sx={{ color: `${primaryColor}` }}
              />
              <VolumeUp />
            </Stack>
          </Grid>
          <Grid item xs={0.5}>
            <input
              type="checkbox"
              checked={!props.toggleOnOff}
              onChange={(e) => props.setToggleOnOff(!e.target.checked)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default AudioSlider;
