import axios from "axios";

// import * as constFile from "./prestigeConst";

// export const publichost = "https://y76-backend-jshl5pugha-uc.a.run.app";
// export const localhost = "http://localhost:4001";

// export const getUsers = () => {
//   return axios
//     .get(`${process.env.REACT_APP_API_KEY + constFile.GET_USERS}`, {})
//     .then((x) => {
//       return x.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

// export const getCourses = () => {
//   return axios
//     .get(`${process.env.REACT_APP_API_KEY + constFile.GET_COURSES}`, {})
//     .then((x) => {
//       return x.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

// export const getCoursesById = (param) => {
//   return axios
//     .get(
//       `${process.env.REACT_APP_API_KEY + constFile.GET_COURSES + "/" + param}`,
//       {}
//     )
//     .then((x) => {
//       return x.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

// export const getSessions = () => {
//   return axios
//     .get(`${process.env.REACT_APP_API_KEY + constFile.GET_SESSIONS}`, {})
//     .then((x) => {
//       return x.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

// export const getSessionsCoursesById = (id) => {
//   return axios
//     .get(
//       `${
//         process.env.REACT_APP_API_KEY +
//         constFile.GET_SESSIONS +
//         "/getCourseSessions"
//       }`,
//       {
//         params: {
//           id: id,
//         },
//       }
//     )
//     .then((x) => {
//       return x.data;
//     })
//     .catch((error) => {
//       return error;
//     });
// };

export const server =
  "https://y76-backend-dev-890718153427.us-central1.run.app"; //development
// export const server = "https://y76-backend-jshl5pugha-uc.a.run.app"; // production
// export const server = "http://localhost:4001"; // local

export const getProjectById = (param) => {
  return axios
    .get(server + `/api/projects/${param}`, {})
    .then((x) => {
      return x;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserById = (param) => {
  return axios
    .get(server + `/api/users/${param}`, {})
    .then((x) => {
      return x;
    })
    .catch((error) => {
      return error;
    });
};
