import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import classes from "./GlobalAudioPlayer.module.css";
import { Grid, IconButton } from "@mui/material";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

const GlobalAudioPlayer = forwardRef(({ src, decible, onPlay, onEnd }, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [volume, setVolume] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loadedPercentage, setLoadedPercentage] = useState(0);

  const audioContext = useRef(null);
  const gainNode = useRef(null);
  const source = useRef(null);
  const audioBuffer = useRef(null);
  const startTime = useRef(0);
  const pauseTime = useRef(0);
  const animationRef = useRef(null);
  const rangeRef = useRef(null);

  const MAX_VOLUME = 200; //200 means 200%

  useEffect(() => {
    audioContext.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    gainNode.current = audioContext.current.createGain();
    gainNode.current.connect(audioContext.current.destination);

    loadAudio();

    return () => {
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
  }, [src]);

  const loadAudio = async () => {
    setIsLoading(true);
    setIsLoaded(false);
    try {
      const response = await fetch(src);
      const arrayBuffer = await response.arrayBuffer();
      const decodedAudio = await audioContext.current.decodeAudioData(
        arrayBuffer
      );
      audioBuffer.current = decodedAudio;
      setDuration(decodedAudio.duration);
      setIsLoaded(true);
      setLoadedPercentage(100); // Set to 100% when fully loaded
    } catch (error) {
      console.error("Error loading audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProgress = () => {
    if (audioBuffer.current) {
      const buffered = audioBuffer.current.duration;
      const percentage = (buffered / duration) * 100;
      setLoadedPercentage(percentage);
    }
  };

  useEffect(() => {
    const updateTime = () => {
      if (isPlaying && audioBuffer.current) {
        const elapsed = audioContext.current.currentTime - startTime.current;
        const newCurrentTime = Math.min(elapsed + pauseTime.current, duration);
        setCurrentTime(newCurrentTime);

        if (newCurrentTime >= duration) {
          resetAudio();
          // Call the onEnd function when audio reaches the end
          if (onEnd) {
            onEnd();
          }
        } else {
          animationRef.current = requestAnimationFrame(updateTime);
        }
      }
    };

    if (isPlaying) {
      animationRef.current = requestAnimationFrame(updateTime);
    } else {
      cancelAnimationFrame(animationRef.current);
    }

    return () => cancelAnimationFrame(animationRef.current);
  }, [isPlaying, duration, onEnd]);

  const resetAudio = () => {
    setIsPlaying(false);
    setCurrentTime(0);
    pauseTime.current = 0;
    if (source.current) {
      source.current.stop();
      source.current = null;
    }
  };

  useEffect(() => {
    const updateRangeProgress = () => {
      if (rangeRef.current) {
        const progress = (currentTime / duration) * 100;
        rangeRef.current.style.setProperty("--range-progress", `${progress}%`);
        rangeRef.current.style.setProperty(
          "--range-loaded",
          `${loadedPercentage}%`
        );
      }
    };

    updateRangeProgress();
  }, [currentTime, duration, loadedPercentage]);

  useEffect(() => {
    const audio = new Audio(src);
    audio.addEventListener("progress", handleProgress);

    return () => {
      audio.removeEventListener("progress", handleProgress);
    };
  }, [src]);

  const toggle = () => {
    if (!isLoaded) return;

    if (isPlaying) {
      if (source.current) {
        source.current.stop();
        source.current = null;
      }
      pauseTime.current = currentTime;
    } else {
      source.current = audioContext.current.createBufferSource();
      source.current.buffer = audioBuffer.current;
      source.current.connect(gainNode.current);
      source.current.start(0, pauseTime.current);
      startTime.current = audioContext.current.currentTime;

      // Call the onPlay function when audio starts playing
      if (onPlay) {
        onPlay();
      }
    }
    setIsPlaying(!isPlaying);
  };

  const changeVolume = (newVolume) => {
    setVolume(newVolume);
    if (gainNode.current) {
      const gainValue = newVolume / 100;
      gainNode.current.gain.setValueAtTime(
        gainValue,
        audioContext.current.currentTime
      );
    }
  };

  const seek = (seekTime) => {
    if (!isLoaded) return;

    setCurrentTime(seekTime);
    pauseTime.current = seekTime;

    if (isPlaying) {
      if (source.current) {
        source.current.stop();
      }
      source.current = audioContext.current.createBufferSource();
      source.current.buffer = audioBuffer.current;
      source.current.connect(gainNode.current);
      source.current.start(0, seekTime);
      startTime.current = audioContext.current.currentTime;
    }
  };

  useImperativeHandle(ref, () => ({
    play: () => {
      if (!isPlaying && isLoaded) toggle();
    },
    pause: () => {
      if (isPlaying) toggle();
    },
    setVolume: changeVolume,
    seek: seek,
    getCurrentTime: () => currentTime,
    getDuration: () => duration,
    isPlaying: () => isPlaying,
    isLoaded: () => isLoaded,
  }));

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(src);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // Extract filename from the src URL or use a default name
      const fileName = src.split("/").pop() || "audio.mp3";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download failed:", error);
      // You might want to show an error message to the user here
    }
  };

  //Volume control
  useEffect(() => {
    if (decible !== null && decible !== undefined) {
      const gain = Math.pow(10, decible / 20); // gain = 10^(dB / 20)
      const newVolume = 100 * gain;
      changeVolume(Math.round(newVolume));
    }
  }, [decible]);

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
      bgcolor="grey"
      style={{ background: "#F1F3F4", borderRadius: "30px" }}
      xs={10}
      height="20px"
    >
      <Grid item xs={1} marginTop={-0.8}>
        <Grid
          onClick={toggle}
          disabled={!isLoaded || isLoading}
          style={{ display: "block", marginBottom: "10px" }}
        >
          {isLoading ? (
            <IconButton
              size="small"
              sx={{
                color: "black",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <RefreshIcon sx={{ fontSize: "1.4rem" }} />
            </IconButton>
          ) : isPlaying ? (
            <IconButton
              size="small"
              sx={{
                color: "black",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <PauseOutlinedIcon sx={{ fontSize: "1.4rem" }} />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              sx={{
                color: "black",
                padding: "4px",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <PlayArrowRoundedIcon sx={{ fontSize: "1.4rem" }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        fontSize="13px"
        fontFamily="Livvic, sans-serif"
        marginTop={-1.5}
      >
        <span>
          {formatTime(currentTime)} / {formatTime(duration)}
        </span>
      </Grid>

      <Grid item xs={5} marginTop={-1.5}>
        <input
          ref={rangeRef}
          className={classes["custom-range-input"]}
          type="range"
          min="0"
          max={duration || 100}
          step={0.001}
          value={currentTime}
          onChange={(e) => seek(parseFloat(e.target.value))}
          disabled={!isLoaded}
        />
      </Grid>

      <Grid item xs={1} marginTop={-1.5}>
        <IconButton
          onClick={handleDownload}
          size="small"
          sx={{
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <DownloadIcon sx={{ fontSize: "1.4rem" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
});

export default GlobalAudioPlayer;

/* <Grid item xs={3} bgcolor="yellow">
        <input
          className="volume-range-input"
          type="range"
          min="0"
          max={MAX_VOLUME}
          value={volume}
          onChange={(e) => changeVolume(parseInt(e.target.value))}
        />
        <span>Volume: {volume}%</span>
      </Grid> */
