import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_SPEECH_FOR_PROJECT } from "../MProjectConst";
import { getProjectById } from "../MProject-services.proxy";
import Loader from "../Components/Loader";
import { convertToDate } from "../utils/generalFunctions";

const ResultsScreen = () => {
  const params = useParams();
  const { id: projectId } = params;

  const [transcriber, setTranscriber] = useState([]);
  const [translator, setTranslator] = useState([]);
  const [qualityassurance, setQualityassurance] = useState([]);
  const [rating, setRating] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProjectById(projectId);

        setTranscriber(response.data.transcribe);
        setTranslator(response.data.translate);
        setQualityassurance(response.data.qualityassurance);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectId]);

  useEffect(() => {
    setDataLoader(true);
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_SPEECH_FOR_PROJECT, {
          projectId: projectId,
        });

        const updatedTotalRating = {};

        response.data.projectsArray.forEach((item) => {
          const rating = item.rate;
          updatedTotalRating[rating] = (updatedTotalRating[rating] || 0) + 1;
        });

        setRating(updatedTotalRating);
        setDataLoader(false);
      } catch (err) {
        console.log(err);
        setDataLoader(false);
      }
    };
    fetchData();
  }, [projectId]);

  const handleUpdateProjectAPI = async () => {
    try {
      const response = await axios.post("", {
        projectId: projectId,
        transcribe: transcriber,
        translate: translator,
        qualityassurance: qualityassurance,
      });
      console.log(response.data.msg);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveTranscribe = (id) => {
    const newItems = transcriber.filter(
      (project) => project.creationDate !== id
    );
    setTranscriber(newItems);
  };

  const handleRemoveTranslate = (id) => {
    const newItems = translator.filter(
      (project) => project.creationDate !== id
    );
    setTranslator(newItems);
  };

  const handleRemoveQA = (id) => {
    const newItems = qualityassurance.filter(
      (project) => project.creationDate !== id
    );
    setQualityassurance(newItems);
  };

  return (
    <Grid item container justifyContent="center">
      <Loader Load={dataLoader} />
      <Grid item container xs={7} marginTop={7} direction="column">
        <Button
          onClick={handleUpdateProjectAPI}
          color="primary"
          variant="contained"
        >
          SAVE AFTER DELETING
        </Button>
      </Grid>

      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Transcribe
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>ROLE</TableCell>
                <TableCell>Submission Date</TableCell>

                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transcriber.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{index}</TableCell>
                  <TableCell>{result.name}</TableCell>
                  <TableCell>{result.role}</TableCell>
                  <TableCell>{convertToDate(result.creationDate)}</TableCell>

                  <TableCell>
                    {/* <Button
                      color="primary"
                      //   onClick={() => navigate(`/admin/results/${results.id}`)}
                    >
                      Review
                    </Button> */}
                    &nbsp;
                    <Button
                      color="error"
                      onClick={() => {
                        handleRemoveTranscribe(result.creationDate);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Translate
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>ROLE</TableCell>
                <TableCell>Submission Date</TableCell>

                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {translator.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{index}</TableCell>
                  <TableCell>{result.name}</TableCell>
                  <TableCell>{result.role}</TableCell>
                  <TableCell>{convertToDate(result.creationDate)}</TableCell>

                  <TableCell>
                    {/* <Button
                      color="primary"
                      //   onClick={() => navigate(`/admin/results/${results.id}`)}
                    >
                      Review
                    </Button> */}

                    <Button
                      color="error"
                      onClick={() => handleRemoveTranslate(result.creationDate)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Quality Assurance
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>ROLE</TableCell>
                <TableCell>Submission Date</TableCell>

                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {qualityassurance.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{index}</TableCell>
                  <TableCell>{result.name}</TableCell>
                  <TableCell>{result.role}</TableCell>
                  <TableCell>{convertToDate(result.creationDate)}</TableCell>

                  <TableCell>
                    {/* <Button
                      color="primary"
                      //   onClick={() => navigate(`/admin/results/${results.id}`)}
                    >
                      Review
                    </Button> */}
                    &nbsp;
                    <Button
                      color="error"
                      onClick={() => handleRemoveQA(result.creationDate)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Dubbing Ratings
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>Stars</TableCell>
                <TableCell>Rated Dialogues Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[1, 2, 3, 4, 5].map((rate) => (
                <TableRow key={rate}>
                  <TableCell> {rate} </TableCell>
                  <TableCell>{rating[rate] || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultsScreen;
