import { createContext, useReducer } from "react";

export const Store = createContext();

const initialState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null,

  userToken: localStorage.getItem("userToken")
    ? JSON.parse(localStorage.getItem("userToken"))
    : {},

  employeTasks: localStorage.getItem("employeTasks")
    ? JSON.parse(localStorage.getItem("employeTasks"))
    : {},

  accessToken: localStorage.getItem("accessToken")
    ? JSON.parse(localStorage.getItem("accessToken"))
    : {},

  projectFile: localStorage.getItem("projectFile")
    ? JSON.parse(localStorage.getItem("projectFile"))
    : {},
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_SIGNIN":
      return { ...state, userInfo: action.payload };
    case "USER_SIGNOUT":
      return {
        ...state,
        userInfo: null,
        sampleCreatedData: {},
        projectFile: {},
      };

    case "SAVE_USER_TOKEN":
      return {
        ...state,
        userToken: action.payload,
      };

    case "SAVE_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
      };

    case "EMPLOYE_TASKS":
      return {
        ...state,
        employeTasks: action.payload,
      };

    case "PROJECT_FILE":
      return {
        ...state,
        projectFile: action.payload,
      };

    case "PROJECT_FILE_CLEAR":
      return {
        ...state,
        projectFile: {},
      };

    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
