import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_PROJECTS } from "../MProjectConst";
import {
  convertToDate,
  getLanguageName,
  getTaskName,
} from "../utils/generalFunctions.js";
import { ToastContainer } from "react-toastify";
import classes from "../Assets/Styles/Global.module.css";
import GlobalButton from "../Components/Buttons/GlobalButton.js";
import { primaryColor, secondaryColor } from "../utils/constants.js";
import { Store } from "../Store.js";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const ProjectListScreen = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { dispatch: ctxDispatch } = useContext(Store);

  useEffect(() => {
    ctxDispatch({ type: "PROJECT_FILE_CLEAR" });
    // localStorage.removeItem("projectFile");
  }, []);

  useEffect(() => {
    setDataLoader(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_PROJECTS);

        const sortedProjects = response.data.projectsArray.sort((a, b) => {
          const dateA = new Date(
            a.creationDate?.seconds * 1000 +
              a.creationDate?.nanoseconds / 1000000
          );
          const dateB = new Date(
            b.creationDate?.seconds * 1000 +
              b.creationDate?.nanoseconds / 1000000
          );

          return dateB - dateA;
        });

        setProjects(sortedProjects);
        setDataLoader(false);
      } catch (err) {
        console.log(err);
        setDataLoader(false);
      }
    };
    fetchData();
  }, []);

  const filteredProjects = useMemo(() => {
    return projects.filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [projects, searchTerm]);
  return (
    <Grid item container justifyContent="center">
      {/* {console.log(projects)} */}
      <ToastContainer position="top-center" theme="dark" />

      <Grid
        item
        container
        xs={10}
        marginTop={7}
        direction="row"
        marginBottom={10}
      >
        <Grid
          item
          xs={3}
          sx={{
            fontSize: "40px",
            fontWeight: "900",
          }}
          className={classes["header"]}
        >
          View Projects
        </Grid>
        <Grid
          item
          container
          xs={12}
          marginBottom={1.5}
          justifyContent="flex-end"
        >
          <Grid item>
            <TextField
              type="text"
              size="small"
              placeholder="Search..."
              style={{ width: "100%", marginBottom: "20px" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        </Grid>

        {dataLoader ? (
          <Grid item xs={12} sx={{ width: 1200 }}>
            {Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={index}
                animation="wave"
                width="100%"
                height={100}
              />
            ))}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Table sx={{ minWidth: 900 }}>
              <TableHead sx={{ backgroundColor: `${secondaryColor}` }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Work Pages</TableCell>
                  <TableCell>Languages</TableCell>

                  <TableCell>Creation Date</TableCell>
                  <TableCell>Admin Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProjects.map((project, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontWeight: "600",
                        maxWidth: "220px",
                      }}
                    >
                      <Grid container item direction="column" gap={1.5}>
                        <Grid item>{project.name}</Grid>
                        <Grid
                          container
                          item
                          justifyContent="center"
                          alignContent="center"
                          alignItems="center"
                        >
                          <Grid item backgroundColor={`${secondaryColor}`}>
                            <IconButton
                              size="small"
                              sx={{
                                color: `${primaryColor}`,
                                "&:hover": {
                                  backgroundColor: `${primaryColor}`,
                                  color: `${secondaryColor}`,
                                },
                              }}
                              onClick={() =>
                                navigate(`/projectlist/${project.id}`)
                              }
                            >
                              <EditNoteIcon sx={{ fontSize: "1.2rem" }} />
                            </IconButton>
                          </Grid>
                          <Grid item bgcolor="#D3D3D3" xs={3.3} height="29px">
                            <Grid item marginTop={0.7}>
                              {getLanguageName(project.orginalLang)}
                            </Grid>
                          </Grid>
                          <Grid item backgroundColor={`${secondaryColor}`}>
                            <a
                              href={project.videoUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  color: `${primaryColor}`,
                                  "&:hover": {
                                    backgroundColor: `${primaryColor}`,
                                    color: `${secondaryColor}`,
                                  },
                                }}
                              >
                                <PlayArrowIcon sx={{ fontSize: "1.2rem" }} />
                              </IconButton>
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <GlobalButton
                        onClick={() => navigate(`/workpages/${project.id}`)}
                        height="45px"
                        width="120px"
                        style={{ fontSize: "13px", padding: "10px 10px" }}
                      >
                        Work Pages
                      </GlobalButton>
                    </TableCell>
                    <TableCell width="10px">
                      <Grid
                        item
                        container
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="center"
                        // bgcolor="red"
                      >
                        {project.finalLang?.map((language, index) => (
                          <Grid
                            xs={6}
                            item
                            container
                            key={index}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Select
                              size="small"
                              color="primary"
                              style={{ minWidth: "200px" }}
                              value={getLanguageName(language)}
                            >
                              <MenuItem
                                value={getLanguageName(language)}
                                disabled={true}
                              >
                                {getLanguageName(language)}
                              </MenuItem>
                              {project.dubbedVideosList?.map((video, index) =>
                                video.language === language ? (
                                  <MenuItem
                                    key={index}
                                    value={video.version}
                                    disabled
                                  >
                                    {video.version} |
                                    {" " + convertToDate(video.creationDate)}
                                  </MenuItem>
                                ) : null
                              )}
                            </Select>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <Grid container width="100%" justifyContent="center">
                        <Grid item> {convertToDate(project.creationDate)}</Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        gap={1.3}
                        direction="column"
                      >
                        <Grid item>
                          <GlobalButton
                            color="primary"
                            height="45px"
                            width="120px"
                            onClick={() => navigate(`/actions/${project.id}`)}
                          >
                            Actions
                          </GlobalButton>
                        </Grid>
                        <Grid item>
                          <Select
                            size="small"
                            color="primary"
                            defaultValue="View Employee"
                          >
                            <MenuItem value="View Employee" disabled={true}>
                              Members
                            </MenuItem>
                            {project.employes?.map((employe, index) => (
                              <MenuItem
                                key={index}
                                value={employe.name}
                                disabled
                              >
                                {employe.name} : {getTaskName(employe.role)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectListScreen;
