import {
  Button,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLanguageName } from "../utils/generalFunctions";
import Loader from "../Components/Loader";
import { getProjectById } from "../MProject-services.proxy";
import axios from "axios";
import {
  AUDIO_CHOPPING,
  AUDIO_CHOPPING_STATUS_CHECK,
  CREATE_ALL_VOCALS_AUTOMATICALLY,
  DELETE_PROJECT,
  SYNC_LIPS,
  TRANSCRIBE,
  TRANSCRIBE_STATUS_CHECK,
  TRANSLATE,
  VOICE_TRAINING,
  VOICE_TRAINING_STATUS_CHECK,
} from "../MProjectConst";
import { ToastContainer, toast } from "react-toastify";
import DeleteConfirmationModal from "../Components/DeleteConfirmationModal";

const ActionsScreen = () => {
  const params = useParams();
  const { id: projectId } = params;
  // const { state } = useContext(Store);
  // const { accessToken } = state;
  const [dataLoader, setDataLoader] = useState(false);
  const [projectFile, setProjectFile] = useState({});
  const [checkTranscribeStatus, setCheckTranscribeStatus] = useState(false);
  const [checkChoppingStatus, setCheckChoppingStatus] = useState(false);
  const [checkAudioTrainingStatus, setcheckAudioTrainingStatus] =
    useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deletedProjectName, setDeletedProjectName] = useState();
  const [deletedProjectId, setDeletedProjectId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProjectById(projectId);

        setProjectFile(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    const checkTranscribeStatus = async () => {
      try {
        const response = await axios.post(TRANSCRIBE_STATUS_CHECK, {
          projectId: projectId,
        });

        setCheckTranscribeStatus(response.data.status);
      } catch (err) {
        console.log(err);
      }
    };
    const checkChoppingStatus = async () => {
      try {
        const response = await axios.post(AUDIO_CHOPPING_STATUS_CHECK, {
          projectId: projectId,
        });

        setCheckChoppingStatus(response.data.status);
      } catch (err) {
        console.log(err);
      }
    };
    const checkAudioTrainingStatus = async () => {
      try {
        const response = await axios.post(VOICE_TRAINING_STATUS_CHECK, {
          projectId: projectId,
        });

        setcheckAudioTrainingStatus(response.data.status);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    checkTranscribeStatus();
    checkChoppingStatus();
    checkAudioTrainingStatus();
  }, []);

  async function transcribe() {
    try {
      const response = await axios.post(TRANSCRIBE, {
        url: projectFile.dialogueAudioUrl,
        projectId: projectId,
        language: projectFile.orginalLang,
      });
      console.log(response.data.taskId);
      toast.success("The movie is being transcribed");

      setCheckTranscribeStatus(true);
    } catch (error) {
      toast.error("An Error has occured while transcribing");
    }
  }

  async function translate(language) {
    try {
      const response = await axios.post(TRANSLATE, {
        projectId: projectId,
        newLanguage: language,
      });

      toast.success(
        "Movie has been translated to " + getLanguageName(language)
      );
    } catch (error) {
      toast.error("An Error has occured while Translating");
    }
  }

  async function audioChopping() {
    try {
      const response = await axios.post(AUDIO_CHOPPING, {
        projectId: projectId,
      });

      toast.success("The Movie original audio has been chopped");
      setCheckChoppingStatus(true);
    } catch (error) {
      toast.error("An Error has occured while Chopping");
    }
  }

  async function audioTraining() {
    try {
      const response = await axios.post(VOICE_TRAINING, {
        projectId: projectId,
      });
      toast.success("The Movie voice id has been trained and added");
      setcheckAudioTrainingStatus(true);
    } catch (error) {
      toast.error("An Error has occured while training the voices");
    }
  }

  async function generateVocals(language) {
    try {
      const response = await axios.post(CREATE_ALL_VOCALS_AUTOMATICALLY, {
        projectId: projectId,
        language: language,
        projectName: projectFile.name,
        creationDate: projectFile.creationDate,
      });

      toast.success(
        "Translated vocals has been requested for all dialogues " +
          getLanguageName(language)
      );
    } catch (error) {
      toast.error("An Error has occured while creating translated vocals");
    }
  }

  async function syncLips(language) {
    try {
      const response = await axios.post(SYNC_LIPS, {
        projectId: projectId,
        language: language,
        projectName: projectFile.name,
        videoUrl: projectFile.videoUrl,
      });

      toast.success(
        "Sync lips has been requested for all scenes for " +
          getLanguageName(language)
      );
    } catch (error) {
      toast.error("An Error has occured while syncing the lips");
    }
  }

  const toggleDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(!showDeleteConfirmModal);
  };

  const handleDeleteProjectAPI = async (id) => {
    try {
      const response = await axios.post(DELETE_PROJECT, {
        id: id,
      });
      console.log(response.data.msg);
      toast.success("Project has been deleted");
    } catch (error) {
      console.log(error);
      toast.error("An Error has occured while deleting the project");
    }
  };

  return (
    <Grid item container justifyContent="center" marginBottom={24.7}>
      <Loader Load={dataLoader} />
      <ToastContainer position="top-center" theme="dark" />
      {/* {console.log(projectFile)} */}

      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
          Actions
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>Names</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Transcription</TableCell>
                <TableCell>
                  <Button
                    onClick={() => transcribe()}
                    disabled={checkTranscribeStatus}
                  >
                    Transcribe
                  </Button>
                </TableCell>
                <TableCell>
                  {checkTranscribeStatus
                    ? "Transcribed"
                    : "Not transcribed yet"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Translation</TableCell>
                <TableCell>
                  <Select color="primary" defaultValue="Translate">
                    <MenuItem value="Translate" disabled={true}>
                      Translate
                    </MenuItem>
                    {projectFile.finalLang?.map((language, index) => (
                      <MenuItem
                        key={index}
                        value={language}
                        onClick={() => translate(language)}
                      >
                        {getLanguageName(language)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Audio Chopping</TableCell>
                <TableCell>
                  <Button
                    onClick={() => audioChopping()}
                    disabled={checkChoppingStatus}
                  >
                    Chop
                  </Button>
                </TableCell>
                <TableCell>
                  {checkChoppingStatus ? "Chopped" : "Not Chopped yet"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Voice Traning</TableCell>
                <TableCell>
                  <Button
                    onClick={() => audioTraining()}
                    disabled={checkAudioTrainingStatus}
                  >
                    Train
                  </Button>
                </TableCell>
                <TableCell>
                  {checkAudioTrainingStatus ? "Trained" : "Not trained yet"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Create Vocals Automatically</TableCell>
                <TableCell>
                  <Select color="primary" defaultValue="Generate">
                    <MenuItem value="Generate" disabled={true}>
                      Generate
                    </MenuItem>
                    {projectFile.finalLang?.map((language, index) => (
                      <MenuItem
                        key={index}
                        value={language}
                        onClick={() => generateVocals(language)}
                      >
                        {getLanguageName(language)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>-</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Sync lips</TableCell>
                <TableCell>
                  <Select color="primary" defaultValue="Sync">
                    <MenuItem value="Sync" disabled={true}>
                      Sync
                    </MenuItem>
                    {projectFile.finalLang?.map((language, index) => (
                      <MenuItem
                        key={index}
                        value={language}
                        onClick={() => syncLips(language)}
                      >
                        {getLanguageName(language)}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item container marginTop={12} direction="column">
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={() => {
              toggleDeleteConfirmModal();
              setDeletedProjectName(projectFile.name);
              setDeletedProjectId(projectId);
              // handleDeleteProjectAPI(project.id);
            }}
          >
            Delete
          </Button>
          {showDeleteConfirmModal && (
            <DeleteConfirmationModal
              projectName={deletedProjectName}
              projectId={deletedProjectId}
              onClose={toggleDeleteConfirmModal}
              handleDeleteProjectAPI={handleDeleteProjectAPI}
              // handleRemoveProject={handleRemoveProject}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActionsScreen;
