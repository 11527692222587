import React from "react";
import { Select, MenuItem, Grid } from "@mui/material";
import GlobalButton from "../Buttons/GlobalButton";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { primaryColor, secondaryColor } from "../../utils/constants";

// Custom colors

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "0.1px",
    // height: "20px",
    borderRadius: 4,
    position: "relative",
    // backgroundColor: "none",
    border: "none",
    fontSize: 16,
    backgroundColor: `${primaryColor}`,
    padding: "0px 0px 5px 4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: `${secondaryColor}`,
    fontFamily: "Livvic",
    // "&:focus": {
    //   borderRadius: 4,
    //   borderColor: "#80bdff",
    //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    // },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: `${primaryColor} !important`,
    color: `${secondaryColor} !important`,
  },
}));

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: `none !important`,
  color: `none !important`,
  fontSize: "15px",
  "&.Mui-selected": {
    backgroundColor: "grey !important",
    color: `none !important`,
  },
  "&:focus": {
    backgroundColor: `grey !important`,
    color: `${primaryColor} !important`,
  },
});

const AdminRolesNavBar = ({
  workTypes,
  projectFile,
  handleWork,
  getTaskNameFormal,
  getLanguageName,
}) => {
  const navigate = useNavigate();
  return (
    <StyledSelect input={<BootstrapInput />}>
      {workTypes?.map((type, index) => (
        <StyledMenuItem key={index}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid>{getTaskNameFormal(type)}</Grid>
            <Grid>
              {[
                "translate",
                "performancedirection",
                "qualityassurance",
                "lipqualityassurance",
              ].includes(type) ? (
                <StyledSelect
                  size="small"
                  defaultValue="Start Working"
                  sx={{ ml: 4 }}
                >
                  <StyledMenuItem value="Start Working">
                    Start Working
                  </StyledMenuItem>
                  {projectFile?.finalLang?.map((language, langIndex) => (
                    <StyledMenuItem
                      key={langIndex}
                      value={language}
                      onClick={() => handleWork(projectFile, type, language)}
                    >
                      {getLanguageName(language)}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              ) : (
                <GlobalButton
                  color="primary"
                  width="150px"
                  onClick={() => handleWork(projectFile, type)}
                >
                  Start Working
                </GlobalButton>
              )}
            </Grid>
          </Grid>
        </StyledMenuItem>
      ))}
      <StyledMenuItem
        onClick={() => navigate(`/workpages/details/${projectFile.id}`)}
      >
        Details page
      </StyledMenuItem>
      <StyledMenuItem onClick={() => navigate(`/actions/${projectFile.id}`)}>
        Action page
      </StyledMenuItem>
    </StyledSelect>
  );
};

export default AdminRolesNavBar;
