export const languagesCode = [
  { name: "Arabic", code: "ar" },
  { name: "Bengali", code: "bn" },
  { name: "Chinese (Simplified)", code: "zh-CN" },
  { name: "Chinese (Traditional)", code: "zh-TW" },
  { name: "Dutch", code: "nl" },
  { name: "English", code: "en" },
  { name: "French", code: "fr" },
  { name: "German", code: "de" },
  { name: "Greek", code: "el" },
  { name: "Hindi", code: "hi" },
  { name: "Italian", code: "it" },
  { name: "Japanese", code: "ja" },
  { name: "Korean", code: "ko" },
  { name: "Latin", code: "la" },
  { name: "Portuguese", code: "pt" },
  { name: "Russian", code: "ru" },
  { name: "Spanish", code: "es" },
  { name: "Swedish", code: "sv" },
  { name: "Tamil", code: "ta" },
  { name: "Thai", code: "th" },
  { name: "Turkish", code: "tr" },
  { name: "Urdu", code: "ur" },
  { name: "Vietnamese", code: "vi" },
  { name: "Welsh", code: "cy" },
  { name: "Finnish", code: "fi" },
  { name: "Norwegian", code: "no" },
  { name: "Polish", code: "pl" },
  { name: "Danish", code: "da" },
  { name: "Hungarian", code: "hu" },
  { name: "Indonesian", code: "id" },
  { name: "Czech", code: "cs" },
  { name: "Slovak", code: "sk" },
  { name: "Slovenian", code: "sl" },
  { name: "Estonian", code: "et" },
  { name: "Latvian", code: "lv" },
  { name: "Spanish (Latin)", code: "sp-la" },
  { name: "Portuguese (Brazil)", code: "pt-br" },
];

// "languages": [
//   { "language_id": "en", "name": "English" },
//   { "language_id": "ja", "name": "Japanese" },
//   { "language_id": "zh", "name": "Chinese" },
//   { "language_id": "de", "name": "German" },
//   { "language_id": "hi", "name": "Hindi" },
//   { "language_id": "fr", "name": "French" },
//   { "language_id": "ko", "name": "Korean" },
//   { "language_id": "pt", "name": "Portuguese" },
//   { "language_id": "it", "name": "Italian" },
//   { "language_id": "es", "name": "Spanish" },
//   { "language_id": "id", "name": "Indonesian" },
//   { "language_id": "nl", "name": "Dutch" },
//   { "language_id": "tr", "name": "Turkish" },
//   { "language_id": "fil", "name": "Filipino" },
//   { "language_id": "pl", "name": "Polish" },
//   { "language_id": "sv", "name": "Swedish" },
//   { "language_id": "bg", "name": "Bulgarian" },
//   { "language_id": "ro", "name": "Romanian" },
//   { "language_id": "ar", "name": "Arabic" },
//   { "language_id": "cs", "name": "Czech" },
//   { "language_id": "el", "name": "Greek" },
//   { "language_id": "fi", "name": "Finnish" },
//   { "language_id": "hr", "name": "Croatian" },
//   { "language_id": "ms", "name": "Malay" },
//   { "language_id": "sk", "name": "Slovak" },
//   { "language_id": "da", "name": "Danish" },
//   { "language_id": "ta", "name": "Tamil" },
//   { "language_id": "uk", "name": "Ukrainian" },
//   { "language_id": "ru", "name": "Russian" }]
