import React, { useState } from "react";
import TaskButton from "./Buttons/TaskButton";
import { ToastContainer, toast } from "react-toastify";

const CopyLinkButton = ({ link }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        toast.success("Link has been copied");
      },
      (err) => toast.error("Link has been copied")
    );
  };

  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />

      <TaskButton onClick={handleCopyClick} height="30px">
        Copy Link
      </TaskButton>
    </div>
  );
};

export default CopyLinkButton;
