import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../Components/Loader";
import axios from "axios";
import { styled } from "@mui/system";
import classes from "../Assets/Styles/Global.module.css";
import { getLanguageName } from "../utils/generalFunctions";
import { GET_SPEECHS_DETAILS_FOR_PROJECT } from "../MProjectConst";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "left",
  fontWeight: "bold",
}));

const ProjectDetailsScreen = () => {
  const params = useParams();
  const { id: projectId } = params;
  const [dataLoader, setDataLoader] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoader(true);
      try {
        const response = await axios.post(GET_SPEECHS_DETAILS_FOR_PROJECT, {
          projectId: projectId,
        });

        setProjectDetails(response.data);
        setDataLoader(false);
      } catch (err) {
        console.log(err);
        setDataLoader(false);
      }
    };

    fetchData();
  }, []);

  function countEmotions(dialogues) {
    // Initialize an empty object to store the counts of each emotion
    const emotionCount = {};

    // Loop through each dialogue in the array
    dialogues.forEach((dialogue) => {
      const emotion = dialogue.originalAudioEmotion;

      // If the emotion exists, increment the count, otherwise, set it to 1
      if (emotionCount[emotion]) {
        emotionCount[emotion] += 1;
      } else {
        emotionCount[emotion] = 1;
      }
    });

    return emotionCount;
  }

  function findCharacterByName(name) {
    // Use the `find` method to search for the character by the given name
    const character = projectDetails.characters.find(
      (character) => character.speaker === name
    );

    // If the character is found, return the displayName if it exists, otherwise return the speaker
    if (character) {
      return character.displayName ? character.displayName : character.speaker;
    }

    // If the character is not found, return a default message
    return name;
  }

  return (
    <Grid item container justifyContent="center" marginBottom={82.9}>
      <Loader Load={dataLoader} />

      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item className={classes["header"]}>
          Project Details
        </Grid>
        {projectDetails && (
          <>
            <Grid item marginTop={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Field</StyledTableCell>
                    <StyledTableCell>Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>Number of dialogues</StyledTableCell>
                    <StyledTableCell>
                      {projectDetails.numberOfDialogues}
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell> Number of true faces</StyledTableCell>
                    <StyledTableCell>
                      {projectDetails.numberOfTrueFaces}
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>
                      {" "}
                      Number of original dialogues
                    </StyledTableCell>
                    <StyledTableCell>
                      {projectDetails.numberOfTrueOriginal}
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell>
                      {" "}
                      Number Of original ratings
                    </StyledTableCell>
                    <StyledTableCell>
                      {"One star: " +
                        projectDetails?.numberOfOriginalRatings?.one +
                        " "}
                      |
                      {" Two stars: " +
                        projectDetails?.numberOfOriginalRatings?.two +
                        " "}
                      |
                      {" Three stars: " +
                        projectDetails?.numberOfOriginalRatings?.three +
                        " "}
                      |
                      {" Four stars: " +
                        projectDetails?.numberOfOriginalRatings?.four +
                        " "}
                      |
                      {" Five stars: " +
                        projectDetails?.numberOfOriginalRatings?.five +
                        " "}
                    </StyledTableCell>
                  </TableRow>

                  {projectDetails?.numberOfratingsTranslations?.map(
                    (rating, index) => (
                      <TableRow key={index}>
                        <StyledTableCell>
                          Number Of original ratings of{" "}
                          {getLanguageName(rating.language)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {"One star: " + rating?.ratings?.one + " "}|
                          {" Two stars: " + rating?.ratings?.two + " "}|
                          {" Three stars: " + rating?.ratings?.three + " "}|
                          {" Four stars: " + rating?.ratings?.four + " "}|
                          {" Five stars: " + rating?.ratings?.five + " "}
                        </StyledTableCell>
                      </TableRow>
                    )
                  )}

                  <TableRow>
                    <StyledTableCell>
                      {" "}
                      Number Of original ratings
                    </StyledTableCell>
                    <StyledTableCell>
                      {"One star: " +
                        projectDetails?.numberOfOriginalRatings?.one +
                        " "}
                      |
                      {" Two stars: " +
                        projectDetails?.numberOfOriginalRatings?.two +
                        " "}
                      |
                      {" Three stars: " +
                        projectDetails?.numberOfOriginalRatings?.three +
                        " "}
                      |
                      {" Four stars: " +
                        projectDetails?.numberOfOriginalRatings?.four +
                        " "}
                      |
                      {" Five stars: " +
                        projectDetails?.numberOfOriginalRatings?.five +
                        " "}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item marginTop={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Characters</StyledTableCell>
                    <StyledTableCell>+4 rating</StyledTableCell>
                  </TableRow>
                </TableHead>

                {projectDetails?.charactersStats &&
                  Object.keys(projectDetails.charactersStats).map(
                    (char, index) => {
                      // Extract dialogues for the current character
                      const dialogues = Object.values(
                        projectDetails.charactersStats[char].dialogues
                      );

                      // Apply the countEmotions function to get emotion counts
                      const emotionCounts = countEmotions(dialogues);
                      const displayName = findCharacterByName(char);

                      return (
                        <TableBody key={index}>
                          <StyledTableCell>{displayName}</StyledTableCell>
                          <StyledTableCell>
                            <p>
                              {/* Display emotion counts */}
                              {Object.keys(emotionCounts).map(
                                (emotion, index) => (
                                  <span key={index}>
                                    {emotion}: {emotionCounts[emotion]}{" "}
                                  </span>
                                )
                              )}
                            </p>
                          </StyledTableCell>
                        </TableBody>
                      );
                    }
                  )}
              </Table>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectDetailsScreen;
