import React from "react";
import classes from "../Assets/Styles/Footer.module.css";
import { Box, Container, Typography } from "@mui/material";

const Footer = ({ loading }) => {
  return (
    <>
      <Box className={classes["footer"]} component="footer" marginTop="1%">
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="body1" marginTop={2} fontFamily={"Livvic"}>
              &copy; {new Date().getFullYear()} DubFrog. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
