import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import { Stack } from "@mui/material";
import SigninScreen from "../Screens/SigninScreen";
import SignupScreen from "../Screens/SignupScreen";
import UserListScreen from "../Screens/UserListScreen";
import UserEditScreen from "../Screens/UserEditScreen";
import ResultsScreen from "../Screens/ResultsScreen";
import LandingAdminPage from "../Screens/LandingAdminPage";
import ProjectCreationScreen from "../Screens/ProjectCreationScreen";
import ProjectListScreen from "../Screens/ProjectListScreen";
import LandingPage from "../Screens/LandingPage";
import ProjectEditScreen from "../Screens/ProjectEditScreen";
import TranscriberScreen from "../Screens/WorkScreens/TranscriberScreen";
import QAScreen from "../Screens/WorkScreens/QAScreen";
import TranslatorScreen from "../Screens/WorkScreens/TranslatorScreen.js";
import SFIScreen from "../Screens/WorkScreens/SFIScreen";
import ActionsScreen from "../Screens/ActionsScreen.js";
import WorkPagesScreen from "../Screens/WorkPagesScreen.js";
import LipQAScreen from "../Screens/WorkScreens/LipQAScreen.js";
import VoiceQAScreen from "../Screens/WorkScreens/VoiceQAScreen.js";
import ProjectDetailsScreen from "../Screens/ProjectDetailsScreen.js";
import Footer from "./Footer.js";
import AdminRoute from "../Components/Validations/AdminRoute.js";

const Navigate = () => {
  return (
    <BrowserRouter>
      <Header />
      <Stack direction="row">
        <Routes>
          <Route
            path="/adminlanding"
            element={
              <AdminRoute>
                <LandingAdminPage />
              </AdminRoute>
            }
          />
          <Route path="/landing" element={<LandingPage />} />
          <Route
            path="/work/translator/:language"
            element={<TranslatorScreen />}
          />
          <Route
            path="/work/performancedirection/:language"
            element={<VoiceQAScreen />}
          />
          <Route path="/work/transcriber" element={<TranscriberScreen />} />
          <Route
            path="/work/speakerfaceidentification"
            element={<SFIScreen />}
          />
          <Route
            path="/work/lipqualityassurance/:language"
            element={<LipQAScreen />}
          />
          <Route
            path="/work/qualityassurance/:language"
            element={<QAScreen />}
          />
          <Route path="/" element={<SigninScreen />} />
          <Route path="/signup" element={<SignupScreen />} />

          <Route
            path="/admin/users"
            element={
              <AdminRoute>
                <UserListScreen />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/user/:id"
            element={
              <AdminRoute>
                <UserEditScreen />
              </AdminRoute>
            }
          />
          <Route path="/results/:id" element={<ResultsScreen />} />
          <Route
            path="/workpages/:id"
            element={
              <AdminRoute>
                <WorkPagesScreen />
              </AdminRoute>
            }
          />
          <Route
            path="/workpages/details/:id"
            element={<ProjectDetailsScreen />}
          />
          <Route
            path="/actions/:id"
            element={
              <AdminRoute>
                <ActionsScreen />
              </AdminRoute>
            }
          />
          <Route
            path="/createproject"
            element={
              <AdminRoute>
                <ProjectCreationScreen />
              </AdminRoute>
            }
          />
          <Route
            path="/projectlist"
            element={
              <AdminRoute>
                <ProjectListScreen />
              </AdminRoute>
            }
          />
          <Route
            path="/projectlist/:id"
            element={
              <AdminRoute>
                <ProjectEditScreen />
              </AdminRoute>
            }
          />
        </Routes>
      </Stack>

      <Footer />
    </BrowserRouter>
  );
};

export default Navigate;
